import React from 'react'
import { useSwiper } from 'swiper/react'

import Icon from '@/components/base/icon'

const FestivalsNavigation = () => {
  const swiper = useSwiper()

  return (
    <>
      <button
        onClick={() => swiper.slidePrev()}
        className="arrowBtn arrowBtn--prev"
      >
        <Icon name="chev-left" size={'small'} />
      </button>
      <button
        onClick={() => swiper.slideNext()}
        className="arrowBtn arrowBtn--next"
      >
        <Icon name="chev-right" size={'small'} />
      </button>
    </>
  )
}

export default FestivalsNavigation
