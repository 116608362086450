import React, { useCallback, useEffect, useRef, useState } from 'react'

import { AnimatePresence, motion, useInView } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper/modules'

import { AppContextProps, useApp, useWidth } from '@wap-client/core'

import { Container, Row } from '@/components/base/gridview'

import FestivalCard from '../festival-card'

import { Festival, FestivalsProps } from './types'

import moment from 'moment'
import SwiperNavigation from '../swiper-navigation'
import { queryService } from '@wap-client/services'
import { IFestival } from '../events/event-filter/types'
import { FESTIVAL } from '../constants'
import { useRouter } from 'next/router'

const cardVariant = {
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    transition: { duration: 0.5, delay: index * 0.25 },
  }),
  hidden: {
    opacity: 0,
    y: -50,
  },
}

const Festivals: React.FunctionComponent<FestivalsProps> = ({ festivalID }) => {
  const app = useApp()
  const router = useRouter()
  const width = useWidth()

  const date = moment().format('YYYY-MM-DD[T]00:00:00')

  const ref = useRef(null)
  const isInView = useInView(ref)

  const [festivals, setFestivals] = useState<Festival[]>([])
  const [filterFestival, setFilterFestival] = useState<Festival[]>([])

  const getFestivals = useCallback(async (query?: string, language?: any) => {
    const params = new URLSearchParams(query)

    if (festivalID) {
      params.append('festivalId', festivalID)
    }

    try {
      const response = await queryService.run<Festival[]>(
        app.environment,
        {
          name: 'get-app-kty-festivaller',
          language: language,
          query: params.toString(),
        },
        FESTIVAL
      )

      if (response) {
        setFestivals(response.data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])

  const filterFestivals = (festivals: Festival[]) => {
    const now: Festival[] = []
    const feture: Festival[] = []
    const past: Festival[] = []

    festivals.map((item: Festival) => {
      if (item.startDate <= date && item.endDate >= date) {
        now.push(item)
      }

      if (item.startDate > date) {
        feture.push(item)
      }

      if (item.endDate < date) {
        past.push(item)
      }
    })

    setFilterFestival([...now, ...feture, ...past])
  }

  useEffect(() => {
    filterFestivals(festivals)
  }, [festivals])

  useEffect(() => {
    getFestivals('', app.language)
  }, [app.language])

  return (
    <section className="festivals" ref={ref}>
      <AnimatePresence>
        <Swiper
          slidesPerView={width > 1025 ? 4 : 1}
          spaceBetween={10}
          width={width < 1025 ? 324 : null}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
        >
          {filterFestival.map((festival, index) => {
            return (
              <SwiperSlide key={index}>
                <motion.div
                  custom={index}
                  variants={cardVariant}
                  animate={isInView ? 'visible' : 'hidden'}
                >
                  <FestivalCard festival={festival} key={index} />
                </motion.div>
              </SwiperSlide>
            )
          })}
          {festivals.length > 4 ? <SwiperNavigation /> : null}
        </Swiper>
      </AnimatePresence>
    </section>
  )
}

export default Festivals
